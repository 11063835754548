import Vue from 'vue';
import VueRouter from 'vue-router'
import Store from '../Store'
import { Routes as UiRoutes, Helpers } from 'truefuture-ui'

const Functions = Helpers.Functions
const Redirect = Helpers.Redirect
const Meta = Helpers.Meta

Vue.use(VueRouter)

const Router = new VueRouter({
    mode: 'history',
    trailingSlash: false,
    strict: true,
    routes: [
        // Routes from UI
        ...UiRoutes,

        // Redirects for old true.world addresses
        { path: '/collections', redirect: '/market/collections' },
        { path: '/launchpad', redirect: '/market/launchpad' },
        //{ path: '/albums', redirect: '/market' },

        // Market
        {
            component: () => import(/* webpackChunkName: 'market' */ '../../components/site/market/Market.vue'),
            path: '/market',
            children: [
                {
                    component: () => import(/* webpackChunkName: 'market' */ 'truefuture-ui/src/components/market/dashboard/ended-events/EndedEventsPage.vue').then(m => m.default || m),
                    path: '/market/launchpad/ended-events',
                    name: 'ended-events',
                    meta: {
                        meta: {
                            title: 'All ended events',
                            description: ''
                        },
                        grey: true
                    },
                },
                {
                    component: () => import(/* webpackChunkName: 'market' */ 'truefuture-ui/src/components/market/dashboard/launchpad/LaunchpadPage.vue').then(m => m.default || m),
                    path: '/market/launchpad',
                    name: 'launchpad',
                    meta: {
                        meta: {
                            title: 'Launchpad: ',
                            description: ''
                        },
                        grey: true
                    },
                },
                {
                    component: () => import(/* webpackChunkName: 'market' */ '../../components/site/market/dashboard/home/MarketHome.vue'),
                    path: '',
                    name: 'market',
                    meta: {
                        meta: {
                            title: 'TRUE Marketplace: where NFT ideas come true',
                            description: 'A multi-chain infrastructure for brands, game developers and users to unleash the power of next generation NFTs and easily integrate them into GameFi products.'
                        }
                    },
                },
                {
                    component: () => import(/* webpackChunkName: 'market' */ 'truefuture-ui/src/components/market/dashboard/nfts/Nfts.vue').then(m => m.default || m),
                    path: '/market/nfts',
                    children: [
                        {
                            component: () => import(/* webpackChunkName: 'market' */ 'truefuture-ui/src/components/market/dashboard/nfts/Nfts.vue').then(m => m.default || m),
                            path: '',
                            name: 'nfts-all',
                            meta: {
                                meta: {
                                    title: 'All NFTs — TRUE Marketplace',
                                    description: 'All NFTs on TRUE Marketplace'
                                }
                            },
                        },
                        {
                            component: () => import(/* webpackChunkName: 'market' */ 'truefuture-ui/src/components/market/dashboard/nfts/Nfts.vue').then(m => m.default || m),
                            path: ':label',
                            name: 'nfts',
                            meta: {
                                meta: {
                                    title: 'All NFTs — TRUE Marketplace',
                                    description: 'All NFTs on TRUE Marketplace'
                                }
                            },
                        }
                    ],
                    meta: {
                        meta: {
                            title: 'All NFTs — TRUE Marketplace',
                            description: 'All NFTs on TRUE Marketplace'
                        }
                    },
                },
                {
                    component: () => import(/* webpackChunkName: 'market' */ 'truefuture-ui/src/components/market/dashboard/collections/Collections.vue'),
                    path: '/market/collections',
                    name: 'collections',
                    meta: {
                        meta: {
                            title: 'Collections — TRUE Marketplace',
                            description: 'NFT collections: a series of NFTS with distinct characteristics.'
                        }
                    },
                },
                {
                    component: () => import(/* webpackChunkName: 'market' */ 'truefuture-ui/src/components/market/dashboard/merchants/Merchants.vue'),
                    path: '/market/merchants',
                    name: 'merchants',
                    meta: {
                        meta: {
                            title: 'Official merchants — TRUE Marketplace',
                            description: 'Official merchants on TRUE Marketplace'
                        }
                    },
                },
                {
                    component: () => import(/* webpackChunkName: 'market' */ '../../components/site/market/dashboard/search/Search.vue'),
                    path: '/market/results',
                    props: true,
                    name: 'search',
                    meta: {
                        meta: {
                            title: 'TRUE Marketplace',
                            description: 'Get access to 100% original NFTs from famous brands combined all in one place'
                        }
                    },
                },
            ]
        },

        // General
        {
            component: () => import(/* webpackChunkName: 'home' */ '../../components/site/home/Home.vue'),
            path: '/',
            name: 'home',
            meta: {
                meta: {
                    title: 'TRUE: SocialFi Gaming Meta',
                    description: 'TRUE: creating synergy between a successful Web2 businesses and a well-established Web3 infrastructure for redefining social gaming.'
                }
            }
        },
        {
            component: () => import(/* webpackChunkName: 'albums' */ '../../components/site/albums/Albums.vue'),
            path: '/albums',
            name: 'albums',
            meta: {
                meta: {
                    title: 'Albums',
                }
            }
        },
        {
            component: () => import(/* webpackChunkName: 'game-world' */ '../../components/site/game-world/GameWorld.vue'),
            path: '/game-world',
            name: 'game-world',
            meta: {
                meta: {
                    title: 'TRUE Metaverse: GameFi Ecosystem for gamers, brands and developers',
                    description: 'Game worlds of our partners where you can play and earn crypto and other assets.',
                },
            },
        },
        {
            component: () => import(/* webpackChunkName: 'metaverse' */ '../../components/site/for-developers/ForDevelopers.vue'),
            path: '/game-world/developers',
            name: 'for-developers',
            meta: {
                meta: {
                    title: 'Ready-to-use Metaverse infrastructure',
                    description: 'Infrastructure for a smooth interaction with NFT by using GameFi innovations',
                },
            },
        },
        {
            component: () => import(/* webpackChunkName: 'metaverse' */ '../../components/site/for-creators/ForCreators.vue'),
            path: '/for-artists',
            name: 'for-creators',
            meta: {
                meta: {
                    title: 'TRUE for GameFi creators',
                    description: 'TRUE for GameFi creators',
                },
            },
        },
        {
            component: () => import(/* webpackChunkName: 'statuses' */ '../../components/site/statuses/Statuses.vue'),
            path: '/statuses',
            name: 'statuses',
            meta: {
                meta: {
                    title: 'TRUE Status cards — first multi-chain NFT Pass on the market',
                    description: 'Provides access to exclusive features, utilities, pre-sales and NFT drops on our platform.'
                }
            },
        },
        {
            component: () => import(/* webpackChunkName: 'community' */ '../../components/site/community/Community.vue'),
            path: '/community',
            name: 'community',
            meta: {
                meta: {
                    title: 'TRUE Community: like-minded people, NFT lovers and TFT holders',
                    description: 'A community of like-minded people and token holders who share our values and enjoy interacting with the game world the way they want and anytime they want.'
                },
            }
        },
        {
            component: () => import(/* webpackChunkName: 'news' */  '../../components/site/community/snippets/NewsBlock'),
            path: '/community/news',
            props: route => ({predefinedTags: route.query.predefinedTags}),
            name: 'news',
            meta: {
                meta: {
                    title: 'News',
                    description: ''
                },
            }
        },
        {
            component: () => import(/* webpackChunkName: 'earn' */ '../../components/site/earn/Earn.vue'),
            path: '/earn',
            name: 'earn',
            meta: {
                meta: {
                    title: 'TRUE Earn: place where your crypto assets work for you',
                    description: 'A place where your cryptocurrency works for you: staking, farming, play-to-earn games, quests & missions and other mechanics.'
                },
            }
        },
        {
            component: () => import(/* webpackChunkName: 'staking' */ '../../components/site/staking/Staking.vue'),
            path: '/earn/staking',
            name: 'staking',
            meta: {
                meta: {
                    title: 'Staking',
                    description: 'Stake your TFT and get rewards'
                },
            }
        },
        {
            component: () => import(/* webpackChunkName: 'staking' */ '../../components/site/swap/Swap.vue'),
            path: '/earn/swap',
            name: 'swap',
            meta: {
                meta: {
                    title: 'Swap',
                    description: 'Swap TFL-TFT and get rewards'
                },
            }
        },
        {
            component: () => import(/* webpackChunkName: 'people' */ '../../components/site/news/News.vue'),
            path: '/community/news/:slug',
            name: 'single-news',
            props: true,
            meta: {
                meta: {
                    title: 'News',
                    description: ''
                },
                grey: true
            }
        },
        {
            component: () => import(/* webpackChunkName: 'contact' */ '../../components/site/contacts/Contacts.vue'),
            path: '/contact-us',
            name: 'contact-us',
            meta: {
                meta: {
                    title: 'Contact Us — True Ecosystem',
                    description: 'Contacts: support, marketing, PR, media, investment, partnerships. True Ecosystem.'
                },
            }
        },
        {
            component: () => import(/* webpackChunkName: 'terms' */ '../../components/site/error/Error.vue'),
            path: '/404',
            name: 'error',
            meta: {
                meta: {
                    title: 'Page not found',
                    description: 'Very suspicious... Please, proceed to the Main page.'
                }
            }
        },
        // {
        //     component: () => import(/* webpackChunkName: 'game-world' */ '../../components/site/nto/TrueNto.vue'),
        //     path: '/true-nto',
        //     name: 'true-nto',
        //     meta: {
        //         meta: {
        //             title: 'Loyalty NTO',
        //             description: '',
        //         },
        //     },
        // },
        // {
        //     component: () => import(/* webpackChunkName: 'terms' */ '../../components/site/get-free-nft/GetFreeNft.vue'),
        //     path: '/get-free-nft/:hash',
        //     name: 'Free Nft',
        //     meta: {
        //         meta: {
        //             title: 'Get Free Nft',
        //             description: ''
        //         }
        //     }
        // },
        {
            component: () => import(/* webpackChunkName: 'terms' */ '../../components/site/terms/TextPage.vue'),
            path: '/terms-swap',
            name: 'terms-swap',
            props: {
                page: 'swap',
                breadcrumb: [
                    {
                        title: 'Swap terms'
                    }
                ],
            },

            meta: {
                meta: {
                    title: 'Terms and conditions of swap-staking',
                    description: ''
                },
                grey: true
            }
        },
        {
            component: () => import(/* webpackChunkName: 'terms' */ '../../components/site/terms/TextPage.vue'),
            path: '/terms-of-use',
            name: 'terms',
            props: {
                page: 'terms',
                breadcrumb: [
                    {
                        title: 'Terms of Use of Website and Services'
                    }
                ],
            },
            meta: {
                meta: {
                    title: 'Terms of Use of Website and Services',
                    description: 'TERMS OF USE OF WEBSITE AND SERVICES. TRUE Ecosystem.'
                },
                grey: true
            }
        },
        {
            component: () => import(/* webpackChunkName: 'terms' */ '../../components/site/terms/TextPage.vue'),
            path: '/privacy-policy',
            name: 'privacy-policy',
            props: {
                page: 'privacy',
                breadcrumb: [
                    {
                        title: 'Privacy Policy',
                    }
                ],
            },
            meta: {
                meta: {
                    title: 'Privacy Policy',
                    description: 'Privacy policy. TRUE Ecosystem.'
                },
                grey: true
            }
        },
        {
            component: () => import(/* webpackChunkName: 'terms' */ '../../components/site/terms/TextPage.vue'),
            path: '/cookies-policy',
            name: 'cookies-policy',
            props: {
                page: 'cookies',
                breadcrumb: [
                    {
                        title: 'Cookies Notice',
                    }
                ],
            },
            meta: {
                meta: {
                    title: 'Cookies Notice',
                    description: 'Cookies Notice. TRUE Ecosystem.'
                },
                grey: true
            }
        },
        {
            path: '/:catchAll(.*)',
            redirect: '/404'
        }
    ]
})

Router.beforeEach(async (to, from, next) => {
    if (from.name) {
        if (from.name == to.name) {
            if (from.hash || to.hash) {
                return false
            }
        }
    }

    Meta.set(to.meta.meta)

    if (to.meta.grey) {
        Functions.greyBody()
    } else {
        Functions.blueBody()
    }

    if (to.meta.auth) {
        await Store.getters.awaitInitStore

        if (Store.state.isGuest) {
            Redirect.set(to.fullPath)

            next('/')

            setTimeout(() => {
                window.location.hash = '#/login/'
            }, 300)
        }
    }
    if (!to.meta.disableScroll || !from.meta.disableScroll) {
        window.scrollTo(0, 0)
    }

    next()
})

Router.afterEach((to, from) => {
    if (!to.meta.disableScroll) {
        window.scrollTo(0, 0)
    }
})

export default Router
